/* eslint-disable no-magic-numbers */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import runTestMessageBus from "./RunTestEventBus.react";

/**
 * PopTest is a component that tests upload / download speeds to a single POP. Not instantiated directly;
 * see PopTestManager for that.
 * ToDo: All tests to use a mutex lock to ensure only one test runs at a time, otherwise speed would be impacted.
 */
export default class PopTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //downloadUrl: "https://download-" + props.popTestName + ".box-test.com/request/download",
            downloadUrl: "https://dl.boxcloud.com/speedtest?size=",
            //uploadUrl: "https://upload-" + props.popTestName + ".box-test.com/request/upload",
            uploadUrl: "https://upload.app.box.com/speedtest",
            uploadSpeed: 0,
            uploadDuration: null,
            uploadRtt: null,
            downloadSpeed: 0,
            downloadDuration: null,
            downloadRtt: null,
            dataSizeMb: 1,
        };

        this.doDownload = this.doDownload.bind(this);
        this.doUpload = this.doUpload.bind(this);
        this.runTests = this.runTests.bind(this);
        this.resetResultsFromState = this.resetResultsFromState.bind(this);
    }

    render() {
        const { id, popDisplayName, } = this.props;
        const {
            downloadSpeed,
            downloadDuration,
            downloadRtt,
            uploadSpeed,
            uploadDuration,
            uploadRtt,
        } = this.state;

        const isDownloadLoading = downloadSpeed === null;
        const isUploadLoading = uploadSpeed === null;

        return (
            <tr id={id}>
                <td><b>{popDisplayName}</b></td>
                <td>
                    {isDownloadLoading
                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : this.state.downloadError
                        ? "Connection Blocked"
                        : `${this.state.downloadSpeed} Mbps`}
                </td>
                <td>{downloadDuration} ms</td>
                <td>{downloadRtt} ms</td>
                <td>
                    {isUploadLoading
                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : this.state.uploadError
                        ? "Connection Blocked"
                        : `${this.state.uploadSpeed} Mbps`}
                </td>
                <td>{uploadDuration} ms</td>
                <td>{uploadRtt} ms</td>
            </tr>
        );
    }

    componentDidMount() {
        runTestMessageBus.on("startPopTest", (data) => {
            // console.log("PopDownloader received event with size: " + data.dataSizeMb);
            this.setState({dataSizeMb: data.dataSizeMb}, () => {
                this.runTests()
            })
        });
        if (this.props.autoRun) {
            this.doDownload();
            this.doUpload();
        }
    }

    resetResultsFromState() {
        // reset state values that are used in output
        this.setState({
            uploadSpeed: null,
            uploadDuration: null,
            uploadRtt: null,
            downloadSpeed: null,
            downloadDuration: null,
            downloadRtt: null,
        })
    }

    runTests() {
        const { id } = this.props
        this.resetResultsFromState()
        this.doUpload()
        this.doDownload()
        runTestMessageBus.dispatch("testcomplete", {id:id});
        // Signal end of tests.
    }

    doDownload() {
        // Downloads a file, update state
        const { id } = this.props
        const { downloadUrl, dataSizeMb } = this.state;
        let downloadSizeUrl;
        switch (dataSizeMb) {
            case 1:
                //downloadSizeUrl = "";
                downloadSizeUrl = "1048576";
                break;
            case 10:
                //downloadSizeUrl = "/10M";
                downloadSizeUrl = "10485760";
                break;
            case 100:
                //downloadSizeUrl = "/100M";
                downloadSizeUrl = "104857600";
                break;
            default:
                //downloadSizeUrl = "";
                downloadSizeUrl = "1048576";
        }
        //const url = downloadUrl + downloadSizeUrl + "?ts=" + Date.now();
        const url = downloadUrl + downloadSizeUrl;

        performance.mark("download-start-" + id)
        const startTime = performance.now();

        // chain the fetch requests promises
        fetch(url, { cache: "no-cache" })
            .then((response) => {
                // we get here as soon as we get a response
                // this doesn't mean we've downloaded the file, file data is still streaming in in the bg

                // Assuming no RTT headers are returned by server hence calculating RTT here
                const rttinMillisecondsdownload = (performance.now() - startTime).toFixed(2);
                this.setState({
                    downloadRtt: rttinMillisecondsdownload,
                });
                if (!response.ok) {
                    if (response.status >= 500) {
                        throw new Error("Server Error");
                    } else  {
                        throw new Error("Connection Blocked");
                    }
                }
                // return a blob, which is another promise - next .then() won't get called until we download the whole thing
                return response.blob();
            })
            .then((blob) => {
                // we only get here once the binary (blob) has completed downloading
                performance.mark("download-end-" + id)
                const downloadMeasure = performance.measure(
                    "download-measure" + id,
                    "download-start-" + id,
                    "download-end-" + id
                )
                const totalDurationSeconds = downloadMeasure.duration / 1000
                const contentLengthAsBits = blob.size * 8;
                const mbps = (((contentLengthAsBits / totalDurationSeconds) / 1024) / 1024).toFixed(2);
                this.setState({
                    downloadSpeed: mbps,
                    downloadDuration: Math.round(downloadMeasure.duration),
                    downloadError: false, // No error, clear error flag
                })
            })
            .catch((error) => {
                this.setState({
                    downloadSpeed: "Connection Blocked",
                    downloadError: true, // Mark as error
                });
                console.error('Download error: ', error);
            });

    }

    readableRandomStringMaker(length) {
        let s
        for (s=''; s.length < length; s += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt(Math.random()*62|0));
        return s;
    }

    doUpload() {
        const { id } = this.props
        const { dataSizeMb, uploadUrl } = this.state
        const url = uploadUrl + "?ts=" + Date.now()
        const bigglyStr = this.readableRandomStringMaker(dataSizeMb*1024*1024)

        // Measure RTT: Only send GET request to get RTT time
        const rttStartTime = performance.now();
        fetch(url, {
            method: "GET",
            cache: "no-cache",
        })
            .then((response) => {
                    // This is a then block to only measure the RTT time
                    const rttinMillisecondsupload = (performance.now() - rttStartTime).toFixed(2);
                    this.setState({
                        uploadRtt: rttinMillisecondsupload,
                    });
                    // Now perform the actual upload request
                    const uploadStartTime = performance.now();
                    performance.mark("upload-start-" + id);

                    return fetch(url, {
                        cache: "no-cache",
                        method: "post",
                        headers: {
                            "Content-Type": "application/octet-stream",
                        },
                        body: bigglyStr,
                    });
            })
            .then((response) => {
                    // This is a then block to measure the upload speed
                    performance.mark("upload-end-" + id)
                    const uploadMeasure = performance.measure(
                        "upload-measure" + id,
                        "upload-start-" + id,
                        "upload-end-" + id
                    )
                    // Mbps math
                    if (!response.ok) {
                        if (response.status >= 500) {
                            throw new Error("Server Error");
                        } else  {
                            throw new Error("Connection Blocked");
                        }
                    }
                    console.log("Upload response status ", response.status)

                    const bigStringSize = new Blob([(bigglyStr)]).size;
                    const totalDurationSeconds = uploadMeasure.duration / 1000
                    const contentLengthAsBits = bigStringSize * 8;
                    const mbps = (((contentLengthAsBits / totalDurationSeconds) / 1024) / 1024).toFixed(2);

                    this.setState({
                        uploadSpeed: mbps,
                        uploadDuration: Math.round(uploadMeasure.duration),
                        uploadError: false, // No error, clear error flag
                    })
                })
                .catch((error) => {
                    this.setState({
                        uploadSpeed: "Connection Blocked",
                        uploadError: true, // Mark as error
                    });
                    console.error('Upload error: ', error);
                });
    }
}

PopTest.defaultProps = {
    autoRun: false,
};

PopTest.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The pop name to display in UI
     */
    popDisplayName: PropTypes.string,


    /**
     * The pop to run download tests on
     */
    popTestName: PropTypes.string,

    /**
     * Should upload / download tests run immediately on load?
     */
    autoRun: PropTypes.bool,
};
